@import "~include-media/dist/_include-media.scss";

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1024px);

html {
  --maxWidth: 1600px;
  margin: 0 auto;
  font-family: 'Roboto';
  font-size: 16px;
  background-color: black;
  }

  h2 {
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;

    @include media(">=phone", "<=tablet") {
      font-size: 1.6rem;
    }
  }