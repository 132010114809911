.complaint-div {
    background-color: #333;
    padding: 40px;
    color: white;

    h2 {
        margin: 0 40px 40px 40px;
        font-style: normal;
        font-size: 2.4rem;
        text-align: center;
        font-weight: bold;
    }

    h3 {
        text-align: center;
        font-weight: bold;
        font-size: 2.0rem;
        margin: 40px 0;
    }

    p {
        text-align: center;
        line-height: 2;
    }

    a {
        text-decoration: none;
        color: #333;
        font-weight: 700;
        padding: 4px 16px;
        background-color: white;
        border-radius: 8px;
        stroke: 1px solid rgb(34, 34, 34);
    }
    .pg-viewer-wrapper {
        overflow: hidden;
        max-width: fit-content;
        .document-container {
            padding: 15px;
            width: auto;
            background: #fff;
            margin: auto;
            max-width: 100%;
        }
    }

    #pg-viewer {
        max-width: 100%;
        p {
            color: #000;
            line-height: normal;
        }
        a {
            padding: 0;
            color: blue;
            background-color: transparent;
            font-weight: 500;
        }
    }

    .pg-viewer-wrapper :link, .pg-viewer-wrapper :visited {
        text-decoration: none;
    }

}

