@import "~include-media/dist/_include-media.scss";

$breakpoints: (
  phone: 319px,
  tablet: 768px,
  desktop: 1024px,
);

.landing-div {
  background-color: #333333;
  display: flex;
  flex-direction: column;

  .description-box {
    margin: 40px 48px 0 48px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include media(">=phone", "<=tablet") {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    img {
      margin-top: 48px;
      margin-left: 40px;
      width: 30rem;

      @include media(">=phone", "<=tablet") {
        width: 15rem;
        margin-left: 0;
      }
    }

    .description {
      margin: 48px 0 0 48px;
      line-height: 1.6;
      @include media(">=phone", "<=tablet") {
        margin: 0 48px;
      }
      p {
        color: white;
      }
    }
  }

  .video-box {
    display: flex;
    flex-direction: column;
    margin-top: 56px;

    h2 {
      color: white;
      font-size: 2.4rem;
      font-weight: bold;
      text-align: center;
      @include media(">=phone", "<=tablet") {
        font-size: 1.6rem;
      }
    }
    .player-wrapper {
        width: 640px;
        height: 360px;
        margin: 40px auto;
    //   .react-player {

    //   }
      @include media(">=phone", "<=tablet") {
        width: 320px;
        height: 180px;
      }
    }
  }
}
