@import "~include-media/dist/_include-media.scss";

$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
);

header {
  display: flex;
  justify-content: left;
  flex-direction: column;

  .header-h2 {
    background-color: #828282;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;

    h2 {
      font-style: normal;
      font-size: 2.8rem;
      font-weight: bold;
      color: white;

      @include media(">=phone", "<=tablet") {
        font-size: 1.6rem;
      }
    }
  }

  nav {
    max-width: 100%;
    height: 40px;
    display: flex;
    padding: 0 96px 0 0;
    align-items: center;
    justify-content: flex-end;
    background-color: white;

    a {
      text-decoration: none;
      padding: 4px 8px;
      font-size: 1.2rem;
      font-weight: bold;
      color: black;
      &:hover {
        text-shadow: 2px 2px 4px grey;
      }
    }
  }

  @include media(">=phone", "<=tablet") {
    flex-direction: column;
    justify-content: center;

    h2 {
      text-align: center;
    }

    nav {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }
}
