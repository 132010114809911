@import "~include-media/dist/_include-media.scss";

$breakpoints: (
  phone: 319px,
  tablet: 768px,
  desktop: 1024px,
);

.media-div {
    background-color: #333333;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .video-box {
      display: flex;
      flex-direction: column;
      margin-top: 56px;

      h2 {
        color: white;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 	1rem;
        @include media(">=phone", "<=tablet") {
          font-size: 1.6rem;
        }
      }
      .player-wrapper {
          width: 640px;
          height: 360px;
          margin: 40px auto;
      //   .react-player {

      //   }
        @include media(">=phone", "<=tablet") {
          width: 320px;
          height: 180px;
        }
      }
    }

}
